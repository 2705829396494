import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next/";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { AWSCredentialResponseModel } from "../../Models/devices";
import VideoBarOptions from "./VideoBarOptions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useViewer } from "../Hooks/WebRTC/index";
import { Role } from "amazon-kinesis-video-streams-webrtc";
import MediaOnboardingDialog from "./RequestVideoOrMicPermission";
import AlocityLoading from "../CustomLoading/AlocityLoading";
import * as actions from "../../../../store/actions";
import "./styles.css";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { EMicPermissionType } from "../../Models/commons";
import Data from "../../Constants/values";
import StoreStateModel from "../../Models/store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //
    },
    sectionVideoFeatures: {
      position: "relative",
    },
    ///////FirstRow//////////////
    firstRow: {
      display: "flex",
      justifyContent: "center",
      justifyItems: "center",
      alignItems: "center",
      backgroundColor: "#222",
      width: "100%",
      // height: "auto",
      height: (props: any) =>
        props.height > 0 ? props.height : props.height === 0 ? "" : "auto !important", //the equal to 0 is because in video intercom=> after return from full screen need to erase the previous height to avoid overlapping

      position: "relative",
    },
    firstRow__container: {
      // position: "relative",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
      justifyItems: "center",
    },
    firstRow__container__video: {
      position: "relative",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      justifyItems: "center",
      // minWidth: "640px",
      minHeight: "auto",
    },
    video: {
      marginBottom: "-7px",
      objectFit: "contain",
      width: "100%  !important",
      // height: "auto   !important",
      height: (props: any) => (props.height > 0 ? props.height : ""),
      // maxHeight: "480px",
      // position: "absolute",
      left: 0,
      top: 0,
      display: "inline-block",
      backgroundColor: (props: any) => (props.isFullScreen ? "black" : ""),
      transform: (props: any) => (props.hasToFlip ? "rotateY(180deg)" : ""),
      "-webkit-transform": (props: any) =>
        props.hasToFlip ? "rotateY(180deg)" : "" /* Safari and Chrome */,
      "-moz-transform": (props: any) => (props.hasToFlip ? "rotateY(180deg)" : "") /* Firefox */,
    },
    firstRow__container__titleLive: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      padding: "5px",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      color: "#FFF",
    },
    firstRow__container__hasAuthorizationToTalk: {
      position: (props: any) => (props.isFullScreen ? "fixed" : "relative"),
      bottom: (props: any) => (props.isFullScreen ? theme.spacing(6) : 0),
      opacity: (props: any) => (props.isFullScreen ? 0.8 : 1),
      left: 0,
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.default,
    },
    loadImage: {
      position: "absolute",
      top: 0,
      left: 0,
      minWidth: "100%",
      minHeight: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    // firstRow__container__textIsConnectedMessage: {
    //   backgroundColor: theme.palette.error.light,
    //   width: "100%",
    //   // color: "white",
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   padding: "5px",
    //   opacity: 0.5,
    // },
    ///
    videoFullScreen: {
      position: "fixed",
      right: 0,
      bottom: 0,
      minWidth: "100%",
      minHeight: "100%",
      width: "100%",
      height: "100%",
    },
  })
);
interface Props {
  serialNumber: string;
  deviceName: string;
  isConnected: boolean;
  webRTCCredentials: AWSCredentialResponseModel;
  userName: string;
  posterB64?: string; // Just get a b64 Image
  isWall?: boolean;
  // getHeight?: (height: number) => void;
  customHeight?: number;
  hasWebCredential: boolean;
  localMedia: MediaStream | undefined;
  micPermissionsType?: EMicPermissionType;
  onClose?: () => void;
  isDomCamera?: boolean;
  onReloadLive: () => void;
}
const LiveCamera = ({
  serialNumber,
  deviceName,
  isConnected,
  webRTCCredentials,
  userName,
  posterB64,
  isWall,
  customHeight,
  hasWebCredential,
  localMedia,
  micPermissionsType,
  onClose,
  isDomCamera,
  onReloadLive,
}: // getHeight,
Props): ReactElement => {
  const [isMicEnabled, setIsMicEnabled] = useState(false);
  const [micPermissionsError, setMicPermissionsError] = useState(false);
  const [isSpeakerEnabled, setIsSpeakerEnabled] = useState(false);
  const [localMediaStream, setLocalMediaStream] = useState<MediaStream | undefined>(localMedia);
  const [poster, setPoster] = useState("");
  const firstRenderRef = useRef(true);
  const dispatch = useDispatch();
  const [requestAuthorizationToTalk, setRequestAuthorizationToTalk] = useState(false);
  const [whoHasTheAuthorizationToTalk, setWhoHasTheAuthorizationToTalk] = useState("");
  const userId = useSelector((state: StoreStateModel) => state.auth.uid, shallowEqual);
  const userIdPlusUserName = userId + "_" + userName;

  useEffect(() => {
    // const codecs = RTCRtpSender.getCapabilities("audio");
    // console.log(JSON.stringify(codecs, null, " "));
    if (!firstRenderRef.current) {
      if (!micPermissionsError) {
        const localAudioTrack = localMediaStream?.getAudioTracks();
        if (localAudioTrack) {
          localAudioTrack[0].enabled = isMicEnabled;
        }
      }
    } else {
      const localAudioTrack = localMediaStream?.getAudioTracks();
      if (localAudioTrack) {
        localAudioTrack[0].enabled = false;
      }
    }

    firstRenderRef.current = false;
  }, [isMicEnabled, localMediaStream, micPermissionsError]);

  useEffect(() => {
    dispatch(actions.addDeviceActiveWebRTCConnection(serialNumber));
    return () => {
      dispatch(actions.deleteDeviceActiveWebRTCConnection(serialNumber));
    };
  }, []);

  const {
    error,
    peer,
    remoteDataMessage,
    connectionStateStatus,
    setStartWebRTC,
    setReload,
    closeConnection,
  } = useViewer({
    localMediaStream: localMediaStream,
    offerToReceiveAudio: webRTCCredentials.isAudioEnabled,
    offerToSendAudio: webRTCCredentials.isAudioEnabled,
    region: process.env.REACT_APP_AWS_REGION as string,
    clientName: userIdPlusUserName,
    channelName: serialNumber,
    credentials: {
      accessKeyId: webRTCCredentials.credential.accessKeyId,
      secretAccessKey: webRTCCredentials.credential.secretAccessKey,
      sessionToken: webRTCCredentials.credential.sessionToken,
    },
    webRTCSettings: {
      credential: webRTCCredentials.webRTCSettings.credential,
      urls: webRTCCredentials.webRTCSettings.urls,
      username: webRTCCredentials.webRTCSettings.username,
    },
    role: Role.VIEWER,
    requestAuthorizationToTalk: requestAuthorizationToTalk,
  });
  const isConnectionDisconnected = Boolean(
    connectionStateStatus === "disconnected" || connectionStateStatus === "failed"
  );
  const [loadingWebRtc, setLoadingWebRtc] = useState(true);

  const [showMessageTimeOut, setShowMessageTimeOut] = useState(false);
  const refTimeOut = useRef<any>(null);
  const refLoadingWebRTC = useRef<boolean>(loadingWebRtc);
  refLoadingWebRTC.current = loadingWebRtc && !error;
  const checkTime = () => {
    if (!refTimeOut.current) {
      refTimeOut.current = setTimeout(() => {
        if (refLoadingWebRTC.current && !isConnectionDisconnected && !error) {
          //
          setShowMessageTimeOut(true);
          closeConnection();
          setLoadingWebRtc(false);
        }
      }, Data.TimeToCloseWebRTCConnection);
    }
  };
  useEffect(() => {
    checkTime();
    return () => clearTimeout(refTimeOut.current);
  }, []);

  useEffect(() => {
    if (fullScreen) {
      setFullScreen(false);
    }
  }, [isConnectionDisconnected]);

  function cleanTheUserName(value: string): string {
    if (!value) {
      return "";
    }
    const splitDelimiter1 = value.split(Data.delimiterOfTheGeneratedUuid);
    const valuesWithUnder = splitDelimiter1[0].split("_");
    const userID = valuesWithUnder[0];

    valuesWithUnder.splice(0, 1);
    for (let index = 0; index < valuesWithUnder.length; index++) {
      if (!valuesWithUnder[index].length) {
        valuesWithUnder.splice(index, 1, "_");
      } else {
        break;
      }
    }

    const firstAndLastName = valuesWithUnder.join("");
    const data = firstAndLastName.split(Data.delimiterBetweenFirstAndLastName);
    const name = data[0] + " " + data[1];
    return name;
  }

  useEffect(() => {
    //receive message from device
    if (remoteDataMessage && remoteDataMessage.length) {
      const data = JSON.parse(remoteDataMessage);
      const theUser = cleanTheUserName(data.speaker);
      setIsMicEnabled(data.status);
      setWhoHasTheAuthorizationToTalk(theUser);
      setRequestAuthorizationToTalk(data.status);
    }
  }, [remoteDataMessage]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const variantHeight =
  //   customHeight != undefined
  //     ? customHeight
  //     : loadingWebRtc
  //     ? matches
  //       ? "340px"
  //       : "540px"
  //     : "100%";
  const [fullScreen, setFullScreen] = useState(false);
  const prefix = "INT";
  const isIntercom = serialNumber.split("-")[1].startsWith(prefix);
  const classes = useStyles({
    isFullScreen: fullScreen,
    height: customHeight,
    hasToFlip: isIntercom,
  });
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>();

  useMemo(() => {
    if (posterB64) {
      const dimensions = matches ? 340 : 540;
      const img = document.createElement("img");

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = dimensions;
        canvas.height = dimensions;

        ctx?.drawImage(img, 0, 0, dimensions, dimensions);

        const dataURI = canvas.toDataURL();

        setPoster(dataURI);
      };

      img.src = posterB64;
    }
  }, [posterB64]);

  // Assign the peer media stream to a video source
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = peer?.remoteMediaStream as MediaStream;
      if (peer?.remoteMediaStream) {
        videoRef.current?.addEventListener("loadeddata", (event: any) => {
          setLoadingWebRtc(false);
          // dispatch(actions.addDeviceActiveWebRTCConnection(serialNumber)); //added in the initial load, to avoid show the "check" button in the ring toast if arrive during the webrtc connection
        });
      }
    }
    return () => {
      videoRef.current?.removeEventListener("loadeddata", () => {
        setLoadingWebRtc(false);
      });
    };
  }, [peer?.remoteMediaStream, videoRef]);

  // const size = useSize(videoRef);

  // useEffect(() => {
  //   if (getHeight) {
  //     getHeight(size.height);
  //   }
  // }, [size]);
  useEffect(() => {
    if (matchesMobile) {
      setFullScreen(false);
    }
  }, [matchesMobile]);
  //
  //control audio from video intercom
  useEffect(() => {
    if (isWall && micPermissionsType != EMicPermissionType.Asked) {
      setStartWebRTC(true);
      if (micPermissionsType == EMicPermissionType.Denied) {
        setMicPermissionsError(true);
      }
      if (micPermissionsType == EMicPermissionType.Granted) {
        if (!localMediaStream?.active) {
          setLocalMediaStream(localMedia);
        }
      }
    }
  }, [isWall, localMedia, micPermissionsType]);
  ////
  return (
    <div className={classes.root}>
      {isWall ? null : (
        <MediaOnboardingDialog
          disabled={!webRTCCredentials.isAudioEnabled}
          onDenied={(value: boolean) => {
            setMicPermissionsError(value);
            setStartWebRTC(true);
          }}
          onLocalMedia={(media: MediaStream | undefined) => {
            setLocalMediaStream(media);
          }}
        />
      )}
      <Grid container className={classes.sectionVideoFeatures}>
        <Grid container className={classes.firstRow}>
          <div className={classes.firstRow__container}>
            <div className={classes.firstRow__container__video}>
              <video
                ref={videoRef as any}
                loop={true}
                id={"videojs"}
                autoPlay
                muted={!isSpeakerEnabled}
                poster={posterB64}
                // className={classes.video}
                playsInline={true}
                controls={false}
                className={clsx(classes.video, {
                  [classes.videoFullScreen]: fullScreen,
                })}
              >
                {/* {isConnected ? <source src="http://www.w3schools.com/html/movie.mp4" type="video/mp4" /> : null}
                <source src="movie.ogg" type="video/ogg" /> */}
                To view this video please enable JavaScript, and consider upgrading to a web browser
                that supports HTML5 video
              </video>
              {loadingWebRtc ? (
                <>
                  <Grid container style={{ position: "absolute", left: 0, top: 0 }}>
                    <Grid container direction="column" alignItems="center">
                      {/* {error ? null : <CircularProgress size={25} />} */}
                      {error ? null : <AlocityLoading />}
                      {error ? (
                        <Typography
                          display="inline"
                          variant="caption"
                          style={{
                            color: "#FFFF",
                            backgroundColor: "red",
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          {t("ErrorGenericWebRTC")}
                        </Typography>
                      ) : (
                        <Typography display="inline" variant="caption" style={{ color: "#FFFF" }}>
                          {" "}
                          {t("ConnectingLiveView")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : null}
              {isConnectionDisconnected ? (
                <Grid
                  style={{
                    position: fullScreen ? "fixed" : "absolute",
                    top: 0,
                    backgroundColor: theme.palette.warning.light,
                    zIndex: 500,
                    padding: theme.spacing(2),
                  }}
                  container
                  justify="center"
                >
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography display="inline" variant="h6">
                      {t("Disconnected")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={() => {
                        onReloadLive();
                      }}
                      size="small"
                      variant="text"
                      color={"primary"}
                    >
                      {t("Reconnect")}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
              {showMessageTimeOut ? (
                <Grid
                  style={{
                    position: fullScreen ? "fixed" : "absolute",
                    top: 0,
                    backgroundColor: theme.palette.warning.light,
                    zIndex: 500,
                    padding: theme.spacing(2),
                  }}
                  container
                  justify="center"
                >
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography display="inline" variant="h6">
                      {t("NetworkError")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center", marginTop: 15 }}
                  >
                    <Button
                      onClick={() => {
                        onClose && onClose();
                      }}
                      size="small"
                      variant="contained"
                      color={"primary"}
                    >
                      {t("Close")}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </div>

            {!loadingWebRtc && !error && !isConnectionDisconnected ? (
              <div className={classes.firstRow__container__titleLive}>
                <Typography
                  display="inline"
                  variant="caption"
                  style={{
                    color: "#fff",
                    border: "1px solid #fff",
                    paddingRight: "2px",
                    paddingLeft: "2px",
                  }}
                >
                  {t("Live")}
                </Typography>
              </div>
            ) : null}
          </div>
        </Grid>
        {isDomCamera ? null : (
          <VideoBarOptions
            isClickedFullScreen={fullScreen}
            serialNumber={serialNumber}
            disabled={loadingWebRtc || isConnectionDisconnected || showMessageTimeOut} //|| Boolean(error)
            deviceName={deviceName}
            videoRef={videoRef.current as HTMLVideoElement} //document.getElementById("videojs") as HTMLVideoElement}
            onFullScreen={() => {
              setFullScreen((prev) => !prev);
              // const element: any = videoRef.current;
              // if (element.requestFullscreen) {
              //   // W3C API
              //   element.requestFullscreen();
              // } else if (element.mozRequestFullScreen) {
              //   // Mozilla current API
              //   element.mozRequestFullScreen();
              // } else if (element.webkitRequestFullScreen) {
              //   // Webkit current API
              //   element.webkitRequestFullScreen();
              // } else if (element.msRequestFullscreen) {
              //   element.msRequestFullscreen();
              // }
            }}
            isMicEnabled={isMicEnabled}
            onChangeMicStatus={() => {
              if (isMicEnabled) {
                //request authorization to talk in the device
                setRequestAuthorizationToTalk(false);
              } else {
                setRequestAuthorizationToTalk(true);
              }

              setIsMicEnabled((prev) => !prev);
            }}
            isSpeakerEnabled={isSpeakerEnabled}
            onChangeSpeakerStatus={() => {
              setIsSpeakerEnabled((prev) => !prev);
            }}
            micPermissionsError={micPermissionsError}
            isWall={isWall}
            hasWebCredential={hasWebCredential}
            isAudioEnabled={webRTCCredentials.isAudioEnabled}
          />
        )}
        {whoHasTheAuthorizationToTalk.length ? (
          <Grid container className={classes.firstRow__container__hasAuthorizationToTalk}>
            <Grid item xs={12}>
              {!fullScreen ? <Divider /> : null}

              <Typography
                display="inline"
                variant="caption"
                style={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                {t("MicrophoneInUseBy") + ":"}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                style={{
                  marginLeft: "2px",
                }}
              >
                {whoHasTheAuthorizationToTalk || t("None")}
              </Typography>

              {!fullScreen ? null : <Divider />}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};
export default LiveCamera;
