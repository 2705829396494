import React, { Fragment, lazy } from "react";

export const LazyDashboard = lazy(() => import("../../Pages/Dashboard"));

export const LazySettings = lazy(() => import("../../Pages/Settings"));

export const LazyProfilePage = lazy(() => import("../../Pages/Profile"));

export const LazySubscriptionsPage = lazy(() => import("../../Pages/Subscriptions"));

export const LazyCameras = lazy(() => import("../../Pages/Cameras"));

export const LazyCameraDetails = lazy(() => import("../../Pages/Cameras/CameraDetails"));

export const LazyUsers = lazy(() => import("../../Pages/Users"));
export const LazyGroups = lazy(() => import("../../Pages/Groups"));

export const LazyDoors = lazy(() => import("../../Pages/Doors"));
export const LazyDetailDoor = lazy(() => import("../../Pages/Doors/DetailDoor"));

export const LazyReports = lazy(() => import("../../Pages/Reports"));

export const LazyAccessLogsReport = lazy(() => import("../../Pages/Reports/AccessLogs"));

export const LazyEventHistoryReport = lazy(() => import("../../Pages/Reports/EventHistory"));

export const LazyCallHistoryReport = lazy(() => import("../../Pages/Reports/CallHistory"));

export const LazyDoorReport = lazy(() => import("../../Pages/Reports/Doors"));

export const LazySiteReport = lazy(() => import("../../Pages/Reports/Site"));

export const LazyScheduleReport = lazy(() => import("../../Pages/Reports/Schedule"));

export const LazyDetailGroup = lazy(() => import("../../Pages/Groups/DetailGroup"));

export const LazyDetailUser = lazy(() => import("../../Pages/Users/DetailUser"));

export const LazySchedules = lazy(() => import("../../Pages/Schedules"));
export const LazyDetailSchedule = lazy(() => import("../../Pages/Schedules/DetailSchedule"));

export const LazyZones = lazy(() => import("../../Pages/Zones"));
export const LazyDetailZone = lazy(() => import("../../Pages/Zones/DetailZone"));

export const LazyRoles = lazy(() => import("../../Pages/Roles"));
export const LazyDetailRole = lazy(() => import("../../Pages/Roles/DetailRole"));

export const LazySites = lazy(() => import("../../Pages/Sites"));
export const LazyDetailSite = lazy(() => import("../../Pages/Sites/DetailSite"));

export const SupportArea = lazy(() => import("../../Pages/SupportArea"));

export const LazyHolidays = lazy(() => import("../../Pages/HolidaysSetting"));

export const LazyAccount = lazy(() => import("../../Pages/Account"));

export const LazyIntegrations = lazy(() => import("../../Pages/Integrations"));

export const LazyDetailWebhook = lazy(() => import("../../Pages/Webhooks/DetailWebhook"));

export const LazyVideoIntercom = lazy(() => import("../../Pages/VideoIntercom"));

export const LazyVisitors = lazy(() => import("../../Pages/Visitors"));
export const LazyDetailVisitor = lazy(() => import("../../Pages/Visitors/DetailVisitor"));
export const LazyVisitorsSchedules = lazy(() => import("../../Pages/SchedulesVisitors"));
export const LazyVisitorsDetailSchedule = lazy(
  () => import("../../Pages/SchedulesVisitors/DetailVisitorSchedule")
);

export const LazyWorkflows = lazy(() => import("../../Pages/Workflows"));
export const LazyDetailWorkflow = lazy(() => import("../../Pages/Workflows/DetailWorkflow"));

export const LazyCatalog = lazy(() => import("../../Pages/Catalog"));

export const LazySensors = lazy(() => import("../../Pages/Sensors"));
export const LazyDetailSensor = lazy(() => import("../../Pages/Sensors/DetailSensor"));

export const LazyDashboardCameras = lazy(() => import("../../Pages/DashboardCameras"));

export const LazyDashboardGateway = lazy(() => import("../../Pages/DashboardGateway"));
export const LazyAppliancesGateway = lazy(() => import("../../Pages/AppliancesGateway"));
export const LazyAppliancesDetails = lazy(
  () => import("../../Pages/AppliancesGateway/AppliancesDetails")
);
export const LazySetupMessages = lazy(() => import("../../Pages/SetupMessages"));
export const LazySetupPanelElevator = lazy(() => import("../../Pages/Elevator/Elevator"));
export const LazySetupFloors = lazy(() => import("../../Pages/Elevator/Floors"));
export const LazySetupPanelRelaysOfElevator = lazy(() => import("../../Pages/Elevator/Relays"));
export const LazyApprovalRequests = lazy(() => import("../../Pages/ApprovalRequests"));
export const LazyApprovalRequestsDetails = lazy(
  () => import("../../Pages/ApprovalRequests/DetailApprovalRequest")
);
